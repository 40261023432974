<template>
  <div class="page">
    <div class="container" v-if="termsConditionsItems && termsConditionsItems.length > 0">
      <div class="page-title mt-4">
        <h1 style="font-size: 2rem">ご利用いただくにあたり</h1>
      </div>
      <p>
        {{ regardingUseText }}
      </p>
      <div class="mt-5" v-for="(item, index) of termsConditionsItems" :key="index">
        <div v-if="item.type === 0">
          <div class="sub-title">
            <h1>{{ item.textName }}</h1>
            <div class="control">
              <a :href="item.pdfSource" target="_blank">
                <font-awesome-icon class="va-m" icon="download"></font-awesome-icon>
                {{item.textName}}をダウンロード
              </a>
            </div>
          </div>
          <div class="pdf-view-wrapper" v-if="item.pdfSource">
            <vue-pdf-embed ref="pdfRef" :source="item.pdfSource" />
          </div>
        </div>
      </div>
      <div class="controls text-center mt-4">
        <b-form-checkbox id="agreed" v-model="agreed" name="agreed" class="d-inline-block" :value="true"
          :unchecked-value="false">
          <span>{{ checkboxText }}</span>
        </b-form-checkbox>
      </div>
      <br />
      <div class="text-center mt-4">
        <j-button variant="primary" size="lg" :disabled="disableCheckbox" v-on:click="onAgreeClick">
          基本情報登録へ進む
        </j-button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.policy-text pre {
  height: 275px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 1rem;
  font-family: "MS Mincho";
  font-size: 1rem;
  border: 1px solid $biz-border;
}
</style>
<script>
// BUG2023-2243
import CompanyInfo from "@/model/CompanyInfo.js";
import TermsConditions from "@/model/TermsConditions.js";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import BuyerSwitch from "@/model/BuyerSwitch.js";

// BUG2023-2243: comment out old logic
// import Contract from '@/model/Contract.js';
export default {
  components: { VuePdfEmbed },
  data: () => ({
    agreed: false,
    buyerPolicy: null,
    getFileStatus: "waiting",
    buyerPolicyFileName: "",
    // BUG2023-2243
    termsConditionsItems: [],
    regardingUseText: "ご利用いただくにあたり、システム利用規約と反社会的勢力排除に関する誓約書に同意したうえでお進みください。",
    checkboxText: "システム利用規約と反社会的勢力排除に関する誓約書に同意します。",
  }),
  async mounted() {
    // BUG2023-2243: comment out old logic
    // this.getBuyerPolicy()

    // BUG2023-2243: get buyer switch
    const buyerSwitchRes = await BuyerSwitch.getBuyerSwitch(
      this.$store.state.user.companyCode
    );
    if (
      buyerSwitchRes &&
      buyerSwitchRes.data &&
      buyerSwitchRes.data.isUseNewSupplierPolicy === false
    ) {
      this.$router.push("/initiate/registration");
    } else {
      // BUG2023-2243: get terms conditions list
      this.getTermsConditionsList();
    }
  },
  computed: {
    disableCheckbox() {
      return (
        this.termsConditionsItems &&
        this.termsConditionsItems.some((item) => item.type === 1) &&
        !this.agreed
      );
    },
  },
  methods: {
    onAgreeClick() {
      if (this.agreed) {
        this.$router.push("/initiate/registration");
      }
    },
    // BUG2023-2243
    getTermsConditionsList() {
      TermsConditions.getTermsConditionsList(
        this.$store.state.user.companyCode
      ).then((res) => {
        this.termsConditionsItems = res.data;
        this.termsConditionsItems.forEach(async (item) => {
          if (item.type === 0) {
            const downloadRes = await CompanyInfo.getDownloadFileLink(
              this.$store.state.user.companyCode,
              item.fileDtoList[0].filePath,
              item.fileDtoList[0].fileName
            );
            this.$set(item, "pdfSource", downloadRes.data.data.url);
          } else {
            if (item.textName) {
              this.checkboxText = item.textName;
            }
            if (item.regardingUse) {
              this.regardingUseText = item.regardingUse;
            }
          }
        });
      });
    },
    // BUG2023-2243: comment out old logic
    // getBuyerPolicy(){
    //   this.getFileStatus="waiting"
    //   console.log(this.$store.state.user.companyCode)
    //   let companyCode = this.$store.state.user.companyCode;
    //   Contract.getTermOfSystem(companyCode).then(result => {
    //     this.getFileStatus="success";
    //     this.buyerPolicyFileName = decodeURI(result.headers['content-disposition'].split("filename=")[1]).replaceAll('.pdf','')
    //     setTimeout(function(file){
    //       const blob = new Blob([file.data], { type: 'application/pdf' })
    //       const blobFile = new File([blob],"file.pdf", { type: 'application/pdf' })
    //       const url = URL.createObjectURL(blobFile);
    //       const fileName = decodeURI(result.headers['content-disposition'].split("filename=")[1])
    //       document.getElementById("contractFileWindow").src = url
    //       document.getElementById("contractDownload").download = fileName
    //       document.getElementById("contractDownload").href = url
    //       console.log(document.getElementById("contractFileWindow").src)
    //     },200, result);
    //   }).catch(reason => {
    //     this.$errorMessage(undefined, {reason})
    //     console.log(reason);
    //     this.getFileStatus="failed"
    //   });
    // },
  },
};
</script>

<style>
.pdf-view-wrapper {
  border: 2px solid #ccc;
  height: 500px;
  overflow-y: scroll;
}
</style>
