import Model from './_base';

export default class TermsConditions extends Model {

    static entity = 'terms-conditions';
    
    constructor() {
        super(TermsConditions.entity);
    }

    // BUG2023-2243
    static getTermsConditionsList(companyCode) {
        return Model.axios.get(this.url('/' + companyCode + '/get-terms-conditions-list'));
    }
}