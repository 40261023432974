import Model from './_base';

export default class BuyerSwitch extends Model {

    static entity = 'buyer-switch';
    
    constructor() {
        super(BuyerSwitch.entity);
    }

    // BUG2023-2243
    static getBuyerSwitch(companyCode) {
        return Model.axios.get(this.url('/' + companyCode + '/get-buyer-switch'));
    }
}